<template>
  <section class="login-wrapper" data-app>
    <div class="form-body">
        <div class="row">
            <div class="img-holder">
                <div class="bg"></div>
                <div class="info-holder">
                    <h3>Welcome to KoloBox</h3>
                    <p>We make it easy to invest in right product, Let your money work for you</p>
                    <img src="@/assets/images/4002800.svg" alt="">
                </div>
            </div>
            <div class="form-holder">
                <div class="form-content">
                    <div class="form-items">
                        <div class="website-logo-inside">
                            <a href="">
                                <div class="logo">
                                    <!-- <img class="logo-size" src="https://fe-staging.kolobox.ng/img/logo-kolobox.fff5e8f1.png" alt=""> -->
                                    <img class="logo-size" src="images/logo-light.svg" alt="">
                                </div>
                            </a>
                        </div>
                        <h3> Activation Process</h3>
                        <p>Please visit your mail to complete process. Thank you.</p>
                        
                      <hr>
                               <router-link class="link" to="/"
                        ><span style="color:#fff">Back Home</span></router-link
                      >
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
   
  </section>
</template>

<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-HP1WKQF3LQ"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-HP1WKQF3LQ');
</script>



