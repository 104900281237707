<template>

  <div class="background_svg" data-app>
    <div class="icon-bar">
  <a href="https://web.facebook.com/Koloboxng" class="facebook"><i class="fab fa-facebook"></i></a> 
   <a href="https://www.instagram.com/kolobox.ng/" class="google"><i class="fab fa-instagram"></i></a> 
  <a href="https://twitter.com/kolobox_ng" class="twitter"><i class="fab fa-twitter"></i></a> 
 <!-- 2349032135216 -->
  <a href="https://wa.me/2347033173118?text=Hello,%20I'll%20like%20to know%20more%20about%20your%20service" class="whatsapp"><i class="fab fa-whatsapp"></i></a>

</div>
    <div class="container">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-md navbar-dark">
            <a class="navbar-brand" href="#">
              <img class="img-fluid" src="img/kolobox-logo.png" alt="Kolobox" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarCollapse">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <a class="nav-link" href="#"
                    >Home <span class="sr-only">(current)</span></a
                  >
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="#faq">Faq</a>
                </li>

                <li class="nav-item">
                  <router-link class="nav-link" to="/support"
                    >Contact Us</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link class="nav-link" to="/signup"
                    >Create An Account</router-link
                  >
                </li>
              <div class="social">
                 <ul>
                   <li>  <a href="https://web.facebook.com/Koloboxng" ><i class="fab fa-facebook" style="font-size:25px;color:#fff"></i></a></li>
                   <li>  <a href="https://www.instagram.com/kolobox.ng/" ><i class="fab fa-instagram" style="font-size:25px;color:#fff"></i></a> </li>
                   <li>  <a href="https://twitter.com/kolobox_ng" ><i class="fab fa-twitter" style="font-size:25px;color:#fff"></i></a> </li>
                   <li>  <a href="https://wa.me/2349032135216?text=Hello,20I'll%20like%20to know%20more%20about%20your%20service" ><i class="fab fa-whatsapp" style="font-size:25px;color:#fff"></i></a></li>
                   </ul>
               </div>
              
              </ul>
 
              <button
                class=" btnRadius btn btn-outline-success my-2 my-sm-0 nav-item"
                type="submit"
              >
                <router-link class="login-link" to="/signin">Login</router-link>
              </button>
              
            </div>
          </nav>
        </div>

        <div class="col-md-7 mt-5 mb-5">
          <p>
            <span class="fontSize1">Invest your</span><br />
            <span class="fontSize2">Spare Change</span><br />

            <span class="fontSize3"
              >Get a healthy, risk averse return on your investments –<br />
              Start investing little and Often, smart, simple, hassle-free.
            </span>

            <span class="fontSize4"
              ><br /><br /><span style="color:#fff">Get access to unrestricted products and now&nbsp;&nbsp;</span>
              <b class="pension-color">Pension products</b></span
            >
          </p>
          <h6 style="color:#fff">Coming Soon</h6>
          <a
            href="https://itunes.apple.com/us/app/kolobox-ng/id1457098146?ls=1&mt=8"
            ><img
              src="img/apple-store-icon.png"
              class="mr-3"
              alt="apple-store-icon"
          /></a>
          <a
            href="https://play.google.com/store/apps/details?id=com.koloprojects"
            ><img src="img/google-play-icon.png" alt="google-play-icon"
          /></a>
          <br />
        </div>

        <div class="col-md-5 text-center ">
          <img
            src="img/animation-slide.gif"
            class="img-fluid"
            alt="animation-slide"
          />
        </div>
      </div>
      <!-- row Ended -->
    </div>
    <!-- row div -->

    <!-- Container Start -->
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <h4 class="font-weight-bold text-dark mt-5">How it works</h4>
            <img
              src="img/heading-underline.png"
              class="mb-5"
              alt="heading-underline"
            />
          </div>
          <div class="row">
            <div class="col-md-4 text-center">
              <p>Step 1</p>
              <img
                src="img/stepC-1.png"
                class="mx-auto d-block mb-4"
                alt="step-one"
              />
              <img
                src="img/Creating-your-account.png"
                class="mx-auto d-block"
                alt="step-two"
              />
              <p class="mb-3 text-center fontWB">
                Creating your account is very simple
              </p>
              <p class="text-center parag-font">
                takes less than a min, Just provide us with a few basic details-
                Name, email, phone,etc
              </p>
            </div>

            <div class="col-md-4 text-center">
              <p>Step 2</p>
              <img
                src="img/stepC-2.png"
                class="mx-auto d-block mb-4"
                alt="step-two"
              />
              <img
                src="img/investment-product.png"
                class="mx-auto d-block"
                alt="investment-product"
              />
              <p class="mb-3 text-center fontWB">
                Choose an investment product
              </p>
              <p class="text-center parag-font">
                Choose an investment product based on your risk appetite.
              </p>
            </div>

            <div class="col-md-4 text-center">
              <p>Step 3</p>
              <img
                src="img/stepC-3.png"
                class="mx-auto d-block mb-4"
                alt="step-three"
              />
              <img
                src="img/connect-bank-account.png"
                class="mx-auto d-block"
                alt="connect-bank"
              />
              <p class="mb-3 text-center fontWB">
                Connect your card/bank account
              </p>
              <p class="text-center parag-font">
                and make an initial payment, you can also select amount to
                invest, & frequency whether daily, weekly monthly etc for
                automatic subscriptions/investments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container Ended -->

    <!-- Container Start -->
    <div class="mt-5 img-fluid">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h4 class="font-weight-bold text-dark">Key Features</h4>
            <img
              src="img/heading-underline.png"
              class="mx-auto d-block"
              alt="heading-underline"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Container Ended -->

    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <img class="img-fluid" src="img/featuredbg.png" alt="featured-bg" />
        </div>

        <div class="col-md-5">
          <ul class="featured1">
            <li class="ml-5">
              <p>
                Set investment goal & <br />
                view potential interest/return
              </p>
            </li>
          </ul>

          <ul class="featured2">
            <li class="ml-5">
              <p>
                Cancel Fixed <br />
                investments Anytime
              </p>
            </li>
          </ul>

          <ul class="featured3">
            <li class="ml-5">
              <p>
                Wide selections of <br />
                investment products
              </p>
            </li>
          </ul>

          <ul class="featured4">
            <li class="ml-5">
              <p>
                No set tenor <br />
                option available
              </p>
            </li>
          </ul>

          <ul class="featured5">
            <li class="ml-5">
              <p>
                Free Monthly <br />
                withdrawal
              </p>
            </li>
          </ul>

          <ul class="featured6">
            <li class="ml-5">
              <p>
                Pension <br />
                Products
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Container Start -->

    <div class="page-header mt-5 mb-5 pt-5">
      <div class="container">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-6 signUpsec pt-5">
                  <p class="mt-5">
                    Where are my <span class="org-color">Investments</span>
                    <br />
                    going?
                  </p>
                </div>

                <div class="col-md-6 text-center classE">
                  <iframe
                    class="laptopFrame"
                    src="https://www.youtube.com/embed/ZzX_bqz2Vyw"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <img class="img-fluid" src="img/signup-com.png" />
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-6 signUpsec pt-5">
                  <p class="mt-5">
                    How Do I <span class="org-color">Sign up</span> <br />
                    For KOLOBOX?
                  </p>
                </div>

                <div class="col-md-6 text-center classE">
                  <iframe
                    class="laptopFrame"
                    src="https://www.youtube.com/embed/GDF1MGe3cgk"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <img class="img-fluid" src="img/signup-com.png" />
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-6 signUpsec pt-5">
                  <p class="mt-5">
                    How Does <span class="org-color">Kolobox</span> <br />
                    work?
                  </p>
                </div>

                <div class="col-md-6 text-center classE">
                  <iframe
                    class="laptopFrame"
                    src="https://www.youtube.com/embed/dIZX5x1_yNY"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <img class="img-fluid" src="img/signup-com.png" />
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="row">
                <div class="col-md-6 signUpsec pt-5">
                  <p class="mt-5">
                    Why <span class="org-color">Kolobox</span>? <br />
                  </p>
                </div>

                <div class="col-md-6 text-center classE">
                  <iframe
                    class="laptopFrame"
                    src="https://www.youtube.com/embed/NZyiZRbG90w"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <img class="img-fluid" src="img/signup-com.png" />
                </div>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>

    <!-- Container Ended -->

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center" id="faq">
            <h4 class="font-weight-bold text-dark text-center">FAQ</h4>
            <img src="img/heading-underline.png" class="mx-auto mb-5" />
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo1"
                  >How do I start?</a
                >

                <div id="demo1" class="collapse">
                  <ol>
                    <li>
                      Visit our website @www.kolobox.ng or download our mobile app via Google play store to register.
                    </li>
                    <li>
                     Click on create account
                    </li>
                    <li> Input the following details:</li>
                    <ul>
                      <li>Name</li>
                      <li>Last Name</li>
                      <li>Phone Number</li>
                      <li>Email</li>
                      <li>Occupation</li>
                      <li>Date of Birth </li>
                      <li>Next of Kin</li>
                      <li>Password</li>
                    </ul>
                    <li>
                     Input the activation code received in the email/phone number provided during account opening in step 2 and 3 respectively
                    </li>
                    <li>Add an investment product</li>
                    <li>
                     Select a subscription (Daily, weekly or monthly) for automated deductions.
                    </li>
                    <li>Make a payment (Card or Bank details)</li>
                    <li>You are all done.</li>
                  </ol>
                </div>
              </div>
            </div>

          
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo3"
                  >How do I login to my account?</a
                >

                <div id="demo3" class="collapse">
                You can login using your registered email address, phone number or face ID.
                </div>
              </div>
            </div>

             <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo2"
                  >How much can I start with?</a
                >

                <div id="demo2" class="collapse">
                You can start with little as low as ₦1000.
                </div>
              </div>
            </div>
            
          </div>

          

          <div class="row">
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo4"
                  >Are there bank charges?</a
                >

                <div id="demo4" class="collapse">
                  <p>No, our service is completely free.</p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo5"
                  >What if I do not have funds in my debit card or bank account?</a
                >

                <div id="demo5" class="collapse">
                If your account is not funded, then we will attempt to withdraw the funds at the same time next day, week or month depending on your investment frequency.
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo6"
                  >How do withdrawals work?</a
                >

                <div id="demo6" class="collapse">
                  <p>
                   You are allowed to make a withdrawal once every 30 days as displayed on your dashboard.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo8"
                  >Are my bank details safe?</a
                >

                <div id="demo8" class="collapse">
                  <p>
                  We work with a PCIDSS-compliant payment processor licensed to handle your card details.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo9"
                  >Can I transfer funds to another customer?</a
                >

                <div id="demo9" class="collapse">
                 <p>
                   Yes. Funds can be transferred to other registered customers, but you can only transfer funds that are unlocked or categorized under “withdraw-able funds” on your dashboard. To transfer funds, log into your account, click on the menu bar at the top left corner of the page, select transfer, click on ‘’Customer’’, select amount you wish to transfer and the Kolobox ID of the recipient
                 </p>
                  
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo10"
                  >Can I move funds from my one product to another?</a
                >

                <div id="demo10" class="collapse">
                  <p>
                   Yes. Funds can be moved from Koloflex to other products. To move funds, login, click on the menu bar at the top left corner of the page, select transfer, click on ‘’Product’’, select amount and the product you wish to move the funds into.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo11"
                  >Can I invest my funds anytime?</a
                >

                <div id="demo11" class="collapse">
                 Yes. You can use the top-up feature to manually fund the account anytime at your convenience.
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo12"
                  >What is my pin?</a
                >

                <div id="demo12" class="collapse">
                  Your pin is a 4-digit code you choose when setting up your profile. To set up your pin; Login, go to settings at the top right of the page, click on pin and select “Create”.
You are encouraged to use a code that is easy to remember, as you cannot withdraw without it. However, if you do forget your pin or want to change it, go to settings, click on “Pin reset”.
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo13"
                  >How do I know my account is on Auto-Subscription?</a
                >

                <div id="demo13" class="collapse">
                  <p>
                   To confirm that your account is on auto-subscription, login to your account, click on the menu bar at the top left corner of the page, select dashboard, if you have ‘’Auto-Subscription’’ written below details, then you are on auto-subscription and vice versa.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo14"
                  >How can I Pause my subscription?</a
                >

                <div id="demo14" class="collapse">
                  You can pause your subscription by logging into your account, clicking on the menu bar at the top left of the page, choose dashboard and click on ‘’Pause Subscription’’. (Please note this must be done at least 72 hours before the next subscription date to take effect the same month).
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo15"
                  >How can I resume my subscription?</a
                >

                <div id="demo15" class="collapse">
                 You can resume your subscription by logging into your account, clicking on the menu bar at the top left of the page, choose dashboard and click on ‘’Resume Subscription’’.
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo16"
                  >How can I reset my password?</a
                >

                <div id="demo16" class="collapse">
                  <p>
                    Log onto <a href="https://kolobox.ng"> www.kolobox.ng </a> or go through the mobile app, input your registered email address, click on ‘’Forgot Password’’, input your registered mail address again, a reset pin will be sent to your email address, click on the link and input the 4-digit code on the provided field.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo17"
                  >What Investments do you offer?
</a
                >

                <div id="demo17" class="collapse">
                  We currently offer the following investments products:
                  <ol>
                    <li> <strong>KOLO FLEX:</strong> This is an unlocked plan that allows flexibility and liquidity of funds.
                      <ul>
                        <li><strong>Tenor:</strong> Investment is made for a period of 30 days.</li>
                        <li><strong>Access:</strong> Withdrawal can be made anytime subsequently.</li>
                        <li><strong>Interest rate:</strong> 6% annual percentage rate (APR)</li>
                        <!-- <li><strong>Interest rate:</strong> 4% annual percentage rate (APR) for 1,000,000k investment</li>
                        <li><strong>Interest rate:</strong> 5% annual percentage rate (APR) for 5,000,000k investment</li> -->
                      </ul>
                    </li>

                     <li> <strong>KOLO TARGET:</strong> This is a locked plan aimed at individuals trying to save towards a target – a car, rent, school fees etc.
                      <ul>
                        <!-- <li><strong>Tenor:</strong>  A fixed return is expected on this class of investment over a period of 3 Months (90days)</li> -->
                        <li><strong>Tenor:</strong>  Return is expected on this class of inverstmaent over a period of 3 Months (90 days).</li>
                        <li><strong>Access:</strong> Withdrawal not be allowed till due date.</li>
                         <li><strong>Interest rate:</strong> 8% annual percentage rate (APR)</li>
                        <!-- <li><strong>Interest rate:</strong> 6% annual percentage rate (APR) for 1,000,000k investment</li>
                        <li><strong>Interest rate:</strong> 6% annual percentage rate (APR) for 5,000,000k investment</li> -->
                      </ul>
                    </li>

                      <!-- <li> <strong>KOLOTARGET PLUS:</strong> This is a division of Kolotarget. It is also a locked plan aimed at individuals who wish to save towards a particular goal.
                      <ul>
                        <li><strong>Tenor:</strong>  A fixed return is expected on this class of investment over a period of 6 months (180 days).</li>
                        <li><strong>Access:</strong> Withdrawal not allowed till due date.</li>
                         <li><strong>Interest rate:</strong> 4% annual percentage rate (APR) for 100,000k investment</li>
                        <li><strong>Interest rate:</strong> 6% annual percentage rate (APR) for 1,000,000k investment</li>
                        <li><strong>Interest rate:</strong> 7% annual percentage rate (APR) for 5,000,000k investment</li>
                      </ul>
                    </li> -->

                      <li> <strong>KOLO GROUP:</strong> This is a locked collective plan where customers can now invest as a group, saving towards better financial opportunities e.g. cooperative society, association etc.
                      <ul>
                        <li><strong>How to Use:</strong>  A user will serve as the Administrator; set up, name the group, invite members and recommend amount and frequency.
 </li>
                        <li><strong>Tenor:</strong>   A fixed return is expected on this class of investment over a period of 6 months (180 days).</li>
                        <li><strong>Access:</strong> Withdrawal not allowed till due date.</li>
                         <li><strong>Interest rate:</strong> 10% annual percentage rate (APR) </li>
                        <!-- <li><strong>Interest rate:</strong> 6% annual percentage rate (APR) for 1,000,000k investment</li>
                        <li><strong>Interest rate:</strong> 7% annual percentage rate (APR) for 5,000,000k investment</li> -->
                      </ul>
                    </li>

                        <li> <strong>KOLO FAMILY:</strong> This is a locked plan for a user to invest and withdraw unbehalf of the family members which no one can access it except the admin only.
                      <ul>
                      
                        <li><strong>Tenor:</strong> A fixed return is expected on this investment class over 6 months (180 days).</li>
                        <li><strong>Access:</strong> Withdrawal not allowed till the due date.</li>
                         <li><strong>Interest rate:</strong> 10% annual percentage rate (APR)</li>
                        <!-- <li><strong>Interest rate:</strong> 6% annual percentage rate (APR) for 1,000,000k investment</li>
                        <li><strong>Interest rate:</strong> 7% annual percentage rate (APR) for 5,000,000k investment</li> -->
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo18"
                  >What happens if the Tenor of my Investment ends?</a
                >

                <div id="demo18" class="collapse">
                  <p>
                   You do not have to do anything. We will automatically notify you with the option to roll over or withdraw the funds. You will continue to earn interest even if you do not withdraw the funds after the end date.
 
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo20"
                  >Do I get interests on my investment?</a
                >
                <div id="demo20" class="collapse">
                  Yes. Interest is accrued and reflected daily.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo21"
                  >Can I cancel my Investment?</a
                >

                <div id="demo21" class="collapse">
                 No, investment cannot be cancelled while funds are locked. However, subscriptions to products can be stopped at any time.
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo23"
                  >Customer Balances- Locked and Unlocked Funds</a
                >

                <div id="demo23" class="collapse">
              <ul>
                <li><strong>Unlocked Plan </strong> refers to investments in a flexible plan that allows you easy access to liquid funds just after a 30-day tenor and subsequently once every month </li>
                 <li> <strong>Locked Plans </strong> refers to investments where funds are locked for tenors between 90, 180 days and above.</li>
                </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="faqbg p-3">
                <a
                  class="ft-siz"
                  href="#"
                  data-toggle="collapse"
                  data-target="#demo24"
                  >How do I contact Kolobox?</a
                >
                <div id="demo24" class="collapse">
                  <p>
                   You can reach us on the following platforms:
                   <ul>
                     <li>Email- <a href="mailto:contact@kolobox.ng">Contact@kolobox.ng</a></li>
                     <li>Phone- <a href="tel:07033173118">07033173118</a></li>
                     <li>Instagram- @Kolobox.NG</li>
                     <li>Facebook- Kolobox.ng</li>
                     <li>Twitter- @Kolobox.NG</li>

                     
                   </ul>





                  </p>
                </div>
              </div>
            </div>
          </div>
      
        </div>

       
      </div>
    </div>
    <!-- Container Ended -->

    <!-- Container start -->

  

    

    <!-- Testimonials -->

    <div class="container">
      <div class="row">
        <div class="text-center mx-auto mt-5">
          <h4 class="font-weight-bold text-center testiColor">Testimonials</h4>
          <img class="mb-5" src="img/heading-underline.png" />
        </div>
      </div>
    </div>

    <!--testi-->
    <!-- Testimonials Section -->
    <div class="testiBGN">
      <div
        class="bg-img-hero"
        style="background-image: url(@/assets/img/bg/bg2.png);"
      >
        <div class="container space-2 space-3--lg">
          <div class="w-lg-75 mx-lg-auto">
            <!-- SVG Quote -->
            <figure class="mx-auto mb-4" style="width: 50px;">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 8 8"
                style="enable-background:new 0 0 8 8;"
                xml:space="preserve"
              >
                <path
                  fill="#ffffff"
                  d="M3,1.3C2,1.7,1.2,2.7,1.2,3.6c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5
              C1.4,6.9,1,6.6,0.7,6.1C0.4,5.6,0.3,4.9,0.3,4.5c0-1.6,0.8-2.9,2.5-3.7L3,1.3z M7.1,1.3c-1,0.4-1.8,1.4-1.8,2.3
              c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5c-0.7,0-1.1-0.3-1.4-0.8
              C4.4,5.6,4.4,4.9,4.4,4.5c0-1.6,0.8-2.9,2.5-3.7L7.1,1.3z"
                />
              </svg>
            </figure>
            <!-- End SVG Quote -->

            <!-- Slick Carousel - Testimonials -->
            <div
              id="testimonialsNavMain"
              class="js-slick-carousel u-slick space-2-bottom"
              data-infinite="true"
              data-autoplay="true"
              data-speed="5000"
              data-nav-for="#testimonialsNavThumb"
            >
              <div class="js-slide">
                <blockquote class="text-white text-center">
                  I started using Kolobox last month to save towards my tour in
                  December and i am astonished at how much I have saved within
                  that short period of time, now i am rest assured my tour will
                  happen and this is all thanks to Kolobox.
                </blockquote>
              </div>
              <div class="js-slide">
                <blockquote class="text-white text-center">
                  Kolobox has made me so fond of investment and has helped
                  curtail my excessive spending habit.With it I can set any
                  target and the best part is i get an interest of 12% APR with
                  KoloTarget. It is such a great App and is super easy to use
                  with no hidden charges.I have no regret joining them.
                </blockquote>
              </div>
              <div class="js-slide">
                <blockquote class="text-white text-center">
                  Having my fitness store and being a personal trainer i have so
                  many tall dreams but my life became easier since i got the
                  plug "KoloboxNG" that has helped me save a lot to achieve most
                  of it. Now i save and get interest accrued,withdraw,buy new
                  stock and reinvest again.
                </blockquote>
              </div>
              <div class="js-slide">
                <blockquote class="text-white text-center">
                  For a long time i used to love doing "Ajo" -The traditional
                  way of saving but i lost interest because there were so many
                  defaults and risk involved.And at the end of this i was
                  frustrated and often times hurt because of late payments and
                  backlog from some members of the contribution.Then came my ray
                  of hope in the person of my bestfriend,Oju who hinted me about
                  this new hip app that i can use for saving and is so easy
                  because with just a couple of clicks i am done with my
                  transactions.The best part is after the initial payment into
                  my E-Wallet my debit card automatically is synced with my
                  account and i don't have to go through the rigour of putting
                  that in everytime i have to save.I could go on and on but the
                  truth is my makeup business and i are grateful we decided to
                  do it the Kolobox way.You should try it too!!!
                </blockquote>
              </div>
              <div class="js-slide">
                <blockquote class="text-white text-center">
                  <p>
                    I have been looking for a simple and easy to use platform
                    that is not so classist and is highly flexible, then my
                    cousin made mention of koloboxng. I tried it out and found
                    out not only does it not add irrelevant charges but like the
                    name implies it is nostalgic and takes me back to my
                    childhood way of saving in a wooden box.
                  </p>

                  <p>Bet everybody should try it!</p>
                </blockquote>
              </div>
            </div>
            <!-- End Slick Carousel - Testimonials -->
          </div>

          <!-- Slick Carousel - Clients -->
          <div
            id="testimonialsNavThumb"
            class="js-slick-carousel u-slick u-slick--transform-off-lg u-slick--pagination-classic"
            data-infinite="true"
            data-autoplay="true"
            data-speed="5000"
            data-center-mode="true"
            data-slides-show="6"
            data-is-thumbs="true"
            data-focus-on-select="true"
            data-nav-for="#testimonialsNavMain"
            data-responsive="[{
             &quot;breakpoint&quot;: 992,
             &quot;settings&quot;: {
               &quot;slidesToShow&quot;: 4
             }
           }, {
             &quot;breakpoint&quot;: 768,
             &quot;settings&quot;: {
               &quot;slidesToShow&quot;: 3
             }
           }, {
             &quot;breakpoint&quot;: 576,
             &quot;settings&quot;: {
               &quot;slidesToShow&quot;: 3
             }
           }, {
             &quot;breakpoint&quot;: 480,
             &quot;settings&quot;: {
               &quot;slidesToShow&quot;: 2
             }
           }]"
          >
            <div class="js-slide pt-5 text-center">
              <img
                class="u-clients"
                src="img/testi-1.png"
                alt="Image Description"
              />
              <p><span class="testiClor">Titi Oyewole</span></p>
            </div>
            <div class="js-slide pt-5 text-center">
              <img
                class="u-clients"
                src="img/testi-2.png"
                alt="Image Description"
              />
              <p><span class="testiClor">Olamide Komolafe</span></p>
            </div>
            <div class="js-slide pt-5 text-center">
              <img
                class="u-clients"
                src="img/testi-3.png"
                alt="Image Description"
              />
              <p><span class="testiClor">Olufunmilayo Oni</span></p>
            </div>
            <div class="js-slide pt-5 text-center">
              <img
                class="u-clients"
                src="img/testi-4.png"
                alt="Image Description"
              />
              <p><span class="testiClor">Yetunde Olawale</span></p>
            </div>
            <div class="js-slide pt-5 text-center">
              <img
                class="u-clients"
                src="img/testi-5.png"
                alt="Image Description"
              />
              <p><span class="testiClor">Tobi Sadiq</span></p>
            </div>
          </div>
          <!-- End Slick Carousel - Clients -->
        </div>
      </div>
    </div>

    <div class="abtBG">
      <div class="container">
        <div class="row">
          <div class="text-center mx-auto mt-5">
            <h4 class="font-weight-bold text-center abMT">About Us</h4>
            <img class="mb-5" src="img/heading-underline.png" />
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <p class="text-center abtiFont">
              <span class="font-weight-bold"
                >Our mission is to empower our customers with tools that enable
                them take charge of their personal finances.</span
              ><br /><br />

              Traditional financial institutions have made saving money and
              growing wealth costly and unattractive to individuals who are
              outside their high net worth bracket. We want to make saving
              worthwhile again for everyone by making it simple to do and highly
              rewarding. We invite you to join us on this wonderful journey.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="footBG">
      <div class="container p-5">
        <div class="row">
          <footer class="footer-font">
            <p class="text-center">
              All Nigerian bank cards accepted and most Nigerian bank accounts
              supported<br />

              <img src="img/payment-icon.png" /><br />
              13 Olarenwaju Street, Ikeja, Lagos<br />
              contact@kolobox.ng<br />
              0700 KOLO BOX(565-6269)<br />
              © 2018 Kolobox Capital Limited | Disclosures<br />
              This website is operated by Kolobox Capital Limited, Funds are
              held and managed by Radix Assests Management Limited, a SEC registered
              Investment/Asset Management Firm. Investing involves risk and
              investments may lose value. Please consider your objectives before
              investing. Past performance does not guarantee future results.
            </p>
          </footer>
        </div>
      </div>
    </div>

    <!-- chatbox -->
 
  </div>
</template>

<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-HP1WKQF3LQ"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-HP1WKQF3LQ');
</script>

<script>
import "@/assets/js/jquery-migrate.min.js";
import "@/assets/js/hs.core.js";
import "@/assets/js/slick.min.js";
import "@/assets/js/hs.slick-carousel.js";

$(document).on("ready", function() {
  // initialization of slick carousel
  $.HSCore.components.HSSlickCarousel.init(".js-slick-carousel");
});

export default {

  methods: {
    redirect(url) {
      window.open(url, "_blank");
    },

  }
};

</script>

<style scoped>
#chatBox {
  font-size: 68px;
  color: green;
  position: fixed;
  bottom: 0px;
  left: 90%;
}

.error--text {
  color: red !important;
  caret-color: red !important;
}

.fontSize1 {
  font-size: 2em;
  color: #ffffff;
  font-weight: 600;
}
.fontSize2 {
  font-size: 3em;
  color: #ff8900;
  font-weight: 600;
}
.fontSize3 {
  font-size: 1em;
  color: #ffffff;
  font-weight: 600;
}
.fontSize4 {
  font-size: 1em;
  color: #ffffff;
  font-weight: 100;
}
.background_svg {
  background-image: url("../../assets/images/topheaderbg.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 630px;
  /*font-family: "Open Sans", sans-serif;*/
}

.link {
  color: white !important;
}
.btnRadius {
  background-color: #ffffff;
  color: #082e61;
  font-size: 13px;
  font-weight: 600;
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 0px solid #000000;
}
.login-link {
  color: #000 !important;
}

.laptopFrame {
  z-index: 3;
  position: absolute;
  margin: 22px 0px 0px 20px;
  width: 80%;
  height: 67%;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .laptopFrame {
    margin: 12px 12px 0px 13px;
    height: 65%;
    width: 85.8%;
  }

}






@media screen and (min-width: 425px) and (max-width: 767px) {
  .laptopFrame {
    margin: 15px 12px 0px 16px;
    height: 65%;
    width: 85.8%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .laptopFrame {
    margin: 14px 12px 0px 14px;
    height: 65%;
    width: 85.8%;
  }
 
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .laptopFrame {
    margin: 15px 12px 0px 19px;
    height: 65%;
    width: 85.8%;
  }
 
}

/* sticky social icon query for mobile */
@media (max-width: 767px) and (min-width:56px) { 
  .icon-bar{
  display: none;
}
}

/* social icon query for tabs and laptops */
@media only screen   
and (min-width: 768px)  
and (max-width: 1605px)  
{ 
    .social ul li{
    display: none;
  }
}
/* social icon query for tabs and laptops */
.social ul {
  display: flex;
}
.social ul li{
   padding:10px;
   list-style: none;

  
}

.social ul li :hover{
  color:#ff8900;
}
.social{
  display: flex;
  justify-content: center;
 
}
.icon-bar {
  position: fixed;
  top: 50%;
  right:20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.whatsapp {
  background: #008012;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}

</style>
